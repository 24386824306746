.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.login-layout{
    width: 400px;
    margin: 0 auto;
    position: relative;

    /*display: table-cell;*/
    height: 100vh;
}

.login-container{
    position: absolute;
    top: 20%;
}



.login-form-container{
    background-color: white;
    /*padding: 90px;*/
    vertical-align: middle;
}

.support-link{
    /*position: absolute;*/
}

.slide-image{
    margin: 30px auto;
}

.login-forgot-password-link{
    width:33%;
    float: left;
}

.fs-14px{
    font-size: 12px;
}
  
.wrapper{
    position: absolute;
    top: 20%;
}

.account-info-row{
    display: block;
    padding: 10px;
    width: 100%;
}

.account-row-field{
    font-weight: bold;
    display: block;
}

.account-row-value{
    padding-left: 5px;
}


@media (max-width: 768px) {
    .container-detail {
        width: 50%;
        padding: 2%;
    }
    .container-header {
        display: flex;
        flex-wrap: wrap;
        flex: 50%; /* or - flex: 0 50% - or - flex-basis: 50% - */
        /*demo*/
    }
    p {
        font-size: 12px; /* Adjust the font size as needed for mobile view */
    }
    .codiLogo {
        display: none;
    }
    .text-base {
        font-size: 12px;
    }
    .text-black {
        font-size: 14px;
    }
    .cell-breakWord {
        word-wrap: break-word;
        max-width: 1px;
    }

    .wrapper{
        position: absolute;
        top: 10%;
    }
}

.error{

}

.error-message, .error.message, .error .message{
    background-color: lightcoral;
    color: red;
}

/* .forgot-email{
    width: 300px;
} */

/*.mobile-view, */.nav-container nav{
                      position: absolute !important;
                      border: 1px solid #999;
                      width:100%;
                      z-index: 1000;
                  }

.mobile-view{
    height: 60px;
}
/*
.mobile-wrapper header{
    margin-bottom: 100px;
}*/

/*.mobile-wrapper{
    height: 200px !important;
}*/

.mobile-menu-wraper{
    height: 60px;
}

.keyword{
    width:33%;
}

.grid-container{
    background-color: #EEE;
}

.grid{
    /* border: solid 1px #EEE;
    background-color: #FFF; */
    margin: 6px;
    text-align: left !important;
    padding: 3px;
}

.grid>div{
    text-align: left !important;
}

.search-bar label{
    float: left;
    display: inline-block;
}

.new-user-btn{
    margin: 0 auto;
}

.new-user-btn-container{
    text-align: center;
}

.contact-form input, .contact-form select{
    border-radius: 40px;
    border: solid 1px #444;
}

/*.contact-form div div{
    width:50%;
    float: left;
}*/

.contact-form>div{
    padding: 20px;
}

.contact-form label{
    padding: 3px;
}

.customer-form input, .customer-form select{
    border-radius: 40px;
    border: solid 1px #444;
}

/*.contact-form div div{
    width:50%;
    float: left;
}*/

.customer-form>div{
    padding: 20px;
}

.customer-form label{
    padding: 3px;
}

.half-width{
    width: 50%;
    padding-left: 20px;
}

.left{
    float: left;
    padding-left: 0px;
    padding-right: 20px;
}

.left-half-width{
    width: 40%;
}

.customer-info-col{
    width: 100%;
}


/*.manage-users-container .search-options{
    display: none;
}*/