@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter-Black.ttf");
}
@font-face {
  font-family: "InterSemiBold";
  src: url("../public/fonts/Inter-SemiBold.ttf");
}
@font-face {
  font-family: "InterMedium";
  src: url("../public/fonts/Inter-Medium.ttf");
}
@font-face {
  font-family: "InterExtraBold";
  src: url("../public/fonts/Inter-ExtraBold.ttf");
}
